<template>
  <div>
    <b-modal
      ref="modalDataShareLeadWithLeaderOrConsultor"
      :title-html="$tc('Atribuir a Lead {name}', totalSelected, { name: '<b>' + nameLead + '</b>'})"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="false"
      :ok-title="$t('Adicionar responsável')"
      :cancel-title="$t('Fechar')"
      :cancel-disabled="showProcessing"
      :ok-disabled="showProcessing"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="addUser"
      @cancel="hideModal"
    >
      <b-overlay
        :show="showProcessing"
        rounded="sm"
      >
        <b-row
          v-if="checkShowAlert === true"
        >
          <b-col md="12">
            <b-alert
              variant="warning"
              show
            >
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="alert-body"
                v-html="$t('Visto que pelo menos um dos Leads selecionados tem apenas um responsável associado, a opção de {acao} não está disponível.', { acao: '<b>' + $t('Remover')+ '</b>'})"
              />
            <!--eslint-enable-->
            </b-alert>
          </b-col>
        </b-row>

        <b-row
          v-for="(rowUser, index) in usersRspLead.filter(user => user.sw069s03!== '')"
          :key="`user-rsp-lead-${rowUser.sw069s03}`"
        >
          <b-col md="8">

            <b-form-group
              :label="$t('Consultor')"
            >
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="createOption(rowUser)"
                append-to-body
                :calculate-position="withPopper"
                :clearable="true"
                :placeholder="$t('Escolha um consultor')"
                :disabled="true"
                :value="createOption(rowUser)[0]"
                label="name"
                item-text="name"
                item-value="id"
              >
                <template #option="{ name }">
                  {{ name }}
                </template>
                <div slot="no-options">
                  {{ $t('Sem resultados') }}
                </div>
              </v-select>
            </b-form-group>

          </b-col>
          <b-col
            md="2"
          >
            <b-form-group
              :label="'\xa0'"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                block
                @click.stop.prevent="changeUser(createOption(rowUser)[0])"
              >
                {{ $t('Trocar') }}
              </b-button>
            </b-form-group>
          </b-col>
          <b-col
            v-if="(usersRspLead.length > 1) && (canRemove(rowUser) === true)"
            md="2"
          >
            <b-form-group
              :label="'\xa0'"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                block
                @click.stop.prevent="removeUser(createOption(rowUser)[0], index)"
              >
                {{ $t('Remover') }}
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row
          v-if="usersRspLead.length === 0"
        >
          <b-col md="12">
            <b-alert
              variant="danger"
              show
              class="mb-0"
            >
              <div
                class="alert-body"
                v-html="$t('Nenhum responsável associado ao Lead')"
              />
            </b-alert>
          </b-col>
        </b-row>

        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              {{ $t('Aguarde...') }}
            </p>
          </div>
        </template>
      </b-overlay>
    </b-modal>

    <b-modal
      ref="modalDataShareLeadWithLeaderOrConsultorAction"
      :title-html="titleModalAction"
      cancel-variant="outline-secondary"
      size="lg"
      body-class=""
      :visible="false"
      :ok-only="false"
      :ok-title="$t('Atribuir')"
      :cancel-title="$t('Fechar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      :cancel-disabled="showProcessing || modalActionTabOptionsLoader"
      :ok-disabled="showProcessing || modalActionTabOptionsLoader"
      @ok="modalActionSave"
      @cancel="modalActionCancel"
    >
      <b-overlay
        :show="showProcessing"
        rounded="sm"
      >
        <validation-observer
          ref="formDataShareLeadWithLeaderOrConsultorAction"
          #default="{}"
        >
          <b-row
            v-if="modalActionUserOptions.length > 0"
          >
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('Consultor')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-model="modalActionUser"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="modalActionUserOptions"
                    append-to-body
                    :calculate-position="withPopper"
                    :clearable="true"
                    :placeholder="$t('Escolha um consultor')"
                    :loading="modalActionTabOptionsLoader"
                    label="name"
                    item-text="name"
                    item-value="id"
                    @input="getSeparadoresByUserTeam"
                  >
                    <template #option="{ name }">
                      {{ name }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>
          <b-row
            v-if="modalActionTabOptions.length > 0"
          >
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-form-group
                  :label="$t('Separador')"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <v-select
                    v-model="modalActionTab"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="modalActionTabOptions"
                    append-to-body
                    :calculate-position="withPopper"
                    :clearable="true"
                    :placeholder="$t('Escolha um separador')"
                    label="name"
                    item-text="name"
                    item-value="tab"
                  >
                    <template #option="{ name }">
                      {{ name }}
                    </template>
                    <div slot="no-options">
                      {{ $t('Sem resultados') }}
                    </div>
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-if="modalActionBookmarksOptions.length > 0"
          >
            <b-col md="12">

              <b-form-group
                :label="$t('Marcadores')"
              >
                <v-select
                  v-model="modalActionBookmarks"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="modalActionBookmarksOptions"
                  append-to-body
                  :calculate-position="withPopper"
                  :multiple="true"
                  label="sw075s03"
                  item-text="sw075s03"
                  item-value="sw075s01"
                >
                  <template #option="{ sw075s03, sw075s04 }">
                    <feather-icon
                      icon="BookmarkIcon"
                      size="18"
                      class="mr-75"
                      :style="{color: sw075s04}"
                    />
                    {{ sw075s03 }}
                  </template>
                  <div slot="no-options">
                    {{ $t('Sem resultados') }}
                  </div>
                </v-select>
              </b-form-group>

            </b-col>
          </b-row>
        </validation-observer>
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              {{ $t('Aguarde, a processar...') }}
            </p>
          </div>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BButton, BAlert, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import btoa from 'btoa'
import Ripple from 'vue-ripple-directive'
import checkNested from 'check-nested'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mixFormGeral } from '../../mixins'

export default {
  directives: {
    Ripple,
  },
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BButton,
    BAlert,
    BOverlay,
  },
  mixins: [mixFormGeral],
  data() {
    return {
      nameLead: null,
      keyReg: null,
      usersRspLead: [],
      rspUnique: [],
      idsSelection: [],

      titleModalAction: '',
      modalActionUserOptions: [],
      modalActionUser: null,
      modalActionTabOptions: [],
      modalActionTabOptionsLoader: false,
      modalActionTab: null,
      modalActionBookmarksOptions: [],
      modalActionBookmarks: [],
      modalActionIDLeadSel: null,
      modalActionModelSel: null,
      modalActionIDUserChange: null,
      modalActionNameLead: '',

      disableModalMain: false,
      showProcessing: false,

      totalSelected: 0,
      nameTypeReg: '',
    }
  },
  computed: {
    checkShowAlert() {
      let numAlerts = 0
      if (this.idsSelection.length > 0) {
        if (this.usersRspLead.length > 0) {
          this.usersRspLead.forEach(rowUser => {
            if (rowUser.sw069s03 !== '') {
              if (this.rspUnique.includes(Number(rowUser.sw069s03))) {
                numAlerts += 1
              }
            }
          })
        }
      }

      if (numAlerts > 1) {
        return true
      }

      return false
    },
  },
  methods: {
    showModal(params) {
      this.nameLead = params.nameLead || ''
      this.keyReg = params.keyReg || null
      this.usersRspLead = params.usersRspLead || []
      this.rspUnique = params.rspUnique || []
      this.idsSelection = params.idsSelection || []
      this.totalSelected = params.totalSelected || 0
      this.nameTypeReg = params.nameTypeReg || 0
      this.$refs.modalDataShareLeadWithLeaderOrConsultor.show()
    },

    async hideModal(clickClose) {
      this.nameLead = ''
      this.keyReg = null
      this.usersRspLead = []
      this.rspUnique = []
      this.idsSelection = []
      this.totalSelected = 0
      this.nameTypeReg = ''
      this.$refs.modalDataShareLeadWithLeaderOrConsultor.hide()

      if (clickClose !== undefined) {
        await this.$parent.fetchListView()
      }
    },

    createOption(rowUser) {
      if (this.idsSelection.length === 0) {
        return [{
          id: `${btoa(rowUser.sw069s03)}_${btoa(rowUser.sw069s01)}_${btoa(rowUser.sw069s04)}`,
          name: rowUser.nome,
        }]
      }

      return [{
        id: btoa(rowUser.sw069s03),
        name: rowUser.nome,
      }]
    },

    canRemove(rowUser) {
      if ((this.usersRspLead.length > 1) && (this.idsSelection.length === 0)) {
        return true
      }

      if (this.idsSelection.length > 0) {
        if (this.rspUnique.includes(Number(rowUser.sw069s03))) {
          return false
        }

        return true
      }

      return false
    },

    async addUser(evt) {
      if (typeof evt.preventDefault === 'function') {
        evt.preventDefault()
      } else {
        this.keyReg = evt.keyReg || ''
        this.nameLead = evt.nameLead || ''
        this.disableModalMain = evt.disableModalMain || false
      }

      this.modalActionModelSel = 'new'
      this.modalActionIDLeadSel = this.keyReg
      this.modalActionIDUserChange = null
      this.modalActionNameLead = this.nameLead
      this.titleModalAction = this.$t('Adicionar responsável ao Lead {name}', { name: `<b>${this.modalActionNameLead}</b>` })

      await this.$store.dispatch('crm_leads_events/getListUsersTeam', { idLeadForm: this.modalActionIDLeadSel, tab: 'partilhadas' }).then(async res => {
        if (checkNested(res, 'users') && (Object.keys(res.users).length > 0)) {
          this.modalActionUserOptions = []
          this.modalActionTabOptions = []
          this.modalActionTabOptionsLoader = false
          this.modalActionBookmarksOptions = []

          Object.keys(res.users).forEach(user => {
            this.modalActionUserOptions.push({
              id: user,
              name: res.users[user],
            })
          })

          this.hideModal()
          this.$refs.modalDataShareLeadWithLeaderOrConsultorAction.show()
        } else {
          this.showMsgErrorRequest({ message: this.$t('Nenhum consultor disponível') })
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },

    async changeUser(userSelected) {
      await this.$swal({
        title: '',
        html: this.$t('Pretende trocar o responsável por outro?'),
        icon: 'info',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const paramsUser = userSelected.id.split('_')

          if ((this.idsSelection.length > 0) && (paramsUser[0] !== '')) {
            // Origem da listagem em acoes em massa
            // openShareLeadWithLeaderOrConsultor_action(JSON.parse(idsLeads), 'change', aParams[0], obj);

            this.modalActionModelSel = 'change'
            this.modalActionNameLead = this.nameLead
            this.titleModalAction = this.$tc('Trocar responsável do Lead', this.totalSelected)

            this.modalActionIDLeadSel = this.idsSelection

            const idUserChange = paramsUser[0]
            this.modalActionIDUserChange = idUserChange

            await this.$store.dispatch('crm_leads_events/getListUsersTeam', { idLeadForm: this.modalActionIDLeadSel, tab: 'partilhadas' }).then(async res => {
              if (checkNested(res, 'users') && (Object.keys(res.users).length > 0)) {
                this.modalActionUserOptions = []
                this.modalActionTabOptions = []
                this.modalActionTabOptionsLoader = false
                this.modalActionBookmarksOptions = []

                Object.keys(res.users).forEach(user => {
                  this.modalActionUserOptions.push({
                    id: user,
                    name: res.users[user],
                  })
                })

                this.hideModal()
                this.$refs.modalDataShareLeadWithLeaderOrConsultorAction.show()
              } else {
                this.showMsgErrorRequest({ message: this.$t('Nenhum consultor disponível') })
              }
            }).catch(error => {
              this.showMsgErrorRequest(error)
            })
          } else if ((paramsUser[0] !== '') && (paramsUser[2] !== '')) {
            // Origem do click do botão do modal de ações
            this.modalActionModelSel = 'change'
            this.modalActionNameLead = this.nameLead
            this.titleModalAction = this.$t('Trocar responsável do Lead {name}', { name: `<b>${this.modalActionNameLead}</b>` })

            const idLeadSel = paramsUser[2]
            this.modalActionIDLeadSel = idLeadSel

            const idUserChange = paramsUser[0]
            this.modalActionIDUserChange = idUserChange

            await this.$store.dispatch('crm_leads_events/getListUsersTeam', { idLeadForm: this.modalActionIDLeadSel, tab: 'partilhadas' }).then(async res => {
              if (checkNested(res, 'users') && (Object.keys(res.users).length > 0)) {
                this.modalActionUserOptions = []
                this.modalActionTabOptions = []
                this.modalActionTabOptionsLoader = false
                this.modalActionBookmarksOptions = []

                Object.keys(res.users).forEach(user => {
                  this.modalActionUserOptions.push({
                    id: user,
                    name: res.users[user],
                  })
                })

                this.hideModal()
                this.$refs.modalDataShareLeadWithLeaderOrConsultorAction.show()
              } else {
                this.showMsgErrorRequest({ message: this.$t('Nenhum consultor disponível') })
              }
            }).catch(error => {
              this.showMsgErrorRequest(error)
            })
          } else {
            this.showMsgErrorRequest({ message: this.$t('Escolha um responsável') })
          }
        }
      })
    },

    async removeUser(selected, index) {
      if (checkNested(selected, 'id') && (selected.id !== '')) {
        this.$swal({
          title: '',
          html: this.$t('Pretende remover o responsável?'),
          icon: 'question',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('Sim'),
          cancelButtonText: this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.showProcessing = true
            this.$store.dispatch('crm_leads_events/deleteUserRspLeadByTeam', { regDel: selected.id, leads: this.idsSelection }).then(msgSuccess => {
              this.showProcessing = false

              if (msgSuccess) {
                this.usersRspLead.splice(index, 1)
                this.showMsgSuccessRequest({ message: msgSuccess })
                this.hideModal(true)
              }
            }).catch(error => {
              this.showProcessing = false
              this.showMsgErrorRequest(error)
            })
          }
        })
      }
    },

    getSeparadoresByUserTeam(user) {
      this.modalActionTabOptions = []
      this.modalActionTab = null
      this.modalActionBookmarksOptions = []
      this.modalActionBookmarks = []

      if (checkNested(user, 'id') && (user.id !== '')) {
        try {
          this.modalActionTabOptionsLoader = true
          this.$store.dispatch('crm_leads_events/getSeparadoresByUserTeam', { user: btoa(user.id), lead: this.modalActionIDLeadSel }).then(data => {
            this.modalActionTabOptionsLoader = false

            this.modalActionTabOptions = data.tabs || []
            this.modalActionBookmarksOptions = data.bookmarks || []

            // Caso so tenha um separador automaticamente seleciona-o
            if (this.modalActionTabOptions.length === 1) {
              const tabSelected = this.modalActionTabOptions.find(o => o.tab !== undefined)
              this.modalActionTab = tabSelected
            }
          }).catch(error => {
            this.modalActionTabOptionsLoader = false
            this.showMsgErrorRequest(error)
          })
        } catch (err) {
          //
        }
      }
    },

    async modalActionSave(evt) {
      evt.preventDefault()
      await this.$refs.formDataShareLeadWithLeaderOrConsultorAction.validate()
        .then(async valid => {
          if (valid === true) {
            this.showProcessing = true

            let bookmarksSel = ''
            if (this.modalActionBookmarks.length > 0) {
              this.modalActionBookmarks.forEach(bookmark => {
                if (checkNested(bookmark, 'sw075s01') && (bookmark.sw075s01 !== '')) {
                  bookmarksSel += ((bookmarksSel) ? ';' : '') + btoa(bookmark.sw075s01)
                }
              })
            }

            await this.$store.dispatch('crm_leads_events/saveChangeRspByLead', {
              sw025s01: this.modalActionIDLeadSel,
              consultor: btoa(this.modalActionUser.id),
              separador: this.modalActionTab.tab,
              mode: this.modalActionModelSel,
              userChange: this.modalActionIDUserChange,
              bookmarks: bookmarksSel,
            }).then(async msgSuccess => {
              this.showProcessing = false

              // const sTempModalActionIDLeadSel = this.modalActionIDLeadSel
              // const sTempModalActionNameLead = this.modalActionNameLead

              this.hideModalAction()

              if (msgSuccess) {
                await this.showMsgSuccessRequest({ message: msgSuccess }).then(() => {
                  // if (this.disableModalMain === false) {
                  //   this.$parent.shareLeadWithLeaderOrConsultor(sTempModalActionIDLeadSel, sTempModalActionNameLead)
                  // }
                  this.hideModal(true)
                })
              }
            }).catch(error => {
              this.showProcessing = false
              this.showMsgErrorRequest(error)
            })
          } else if (valid === false) {
            if (checkNested(this.modalActionUser, 'id') && (this.modalActionUser.id !== '')) {
              if (checkNested(this.modalActionTab, 'tab') && (this.modalActionTab.tab !== '')) {
                //
              } else {
                this.showMsgErrorRequest({ message: this.$t('Escolha um separador para atribuir a Lead') })
              }
            } else {
              this.showMsgErrorRequest({ message: this.$t('Escolha um consultor') })
            }
          }
        })
    },

    async hideModalAction() {
      this.$refs.modalDataShareLeadWithLeaderOrConsultorAction.hide()
      this.titleModalAction = null
      this.modalActionUserOptions = []
      this.modalActionUser = null
      this.modalActionTabOptions = []
      this.modalActionTabOptionsLoader = false
      this.modalActionTab = null
      this.modalActionBookmarksOptions = []
      this.modalActionBookmarks = []
      this.modalActionIDLeadSel = null
      this.modalActionModelSel = null
      this.modalActionIDUserChange = null
      this.modalActionNameLead = ''
      this.showProcessing = false
    },

    modalActionCancel() {
      const sTempModalActionIDLeadSel = this.modalActionIDLeadSel
      const sTempModalActionNameLead = this.modalActionNameLead

      this.hideModalAction()

      if (this.disableModalMain === false) {
        this.$parent.shareLeadWithLeaderOrConsultor(sTempModalActionIDLeadSel, sTempModalActionNameLead)
      }
    },
  },
}
</script>
